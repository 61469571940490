import smallHeaderImage from '../assets/hotspot/headerImages/farah-s.png';
import largeHeaderImage from '../assets/hotspot/headerImages/farah-l.png';
import introImage from '../assets/hotspot/intro/farah.png';
import postcard from '../assets/hotspot/postcardPdf/Farah_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/FarahPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/farah_concert.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/farah-ya-aroos.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/farah-reedaha.png';


export const farah = {
  name: 'Farah',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Farah, who sings Jordanian Folk music!',
    details: '',
    vimeoLink: 'https://vimeo.com/790530990',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/wjY2pMyJr1U',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Farah',
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Farah sing “Ya Aroos il-Bahr”',
      link: 'Learn “Ya Aroos il-Bahr”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Farah/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1428907477%3Fsecret_token%3Ds-qc9AeHMOXAy&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Farah sing “Reedaha”',
      link: 'Learn “Reedaha”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Farah/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1428907381%3Fsecret_token%3Ds-NLgGxMmFRZD&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  traditionBlurb: 'Jordan has many distinct regions, each with its own musical traditions. For example, the songs of the desert reflect the slower-paced life of the Bedouins, with a heavy emphasis on improvisational poetry, while the fast-paced songs and rhythms are in keeping with the pulse of life in Jordan’s cities. Melody is the primary element in all Jordanian folk songs.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/YmfEVD0zWO8',
      videoImage: celebrationThumbnail1,
      songTitle: 'Ya Aroos il-Bahr',
      artistName: 'Farah',
      videoTags: [
        {
          tag: 'Jordanian Independence Day',
        },
      ],
      songDescription: '"Ya Aroos il-Bahr" is a song from the coast of Jordan inspired by the Red Sea. The song paints a picture of  the city Aqaba ornamented with flowers, palm trees, and palm leaves floating in the water.',
    },
    {
      videoLink: 'https://youtu.be/sHtuXCGTX-8',
      videoImage: celebrationThumbnail2,
      songTitle: 'Reedaha / Ya Khayal il Zarga',
      artistName: 'Farah',
      videoTags: [
        {
          tag: 'Arab-American History Month',
        },
      ],
      songDescription: '"Reedaha" is a very old song passed orally thorugh generations, and this one folds in the song "Ya Khayal il Zarga." Both songs are ghazals, a type of love song that might express flirtation, longing, or a loss of love.',
    },
  ],
};
