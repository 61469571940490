import coverPhoto from '../assets/hotspot/celebrationCover/green-corn-ceremony-2.jpg';
import concertThumbnail from '../assets/hotspot/celebrationPerformances/martha-social-dances.png';
import marthaImage from '../assets/hotspot/headerImages/martha-l.jpg';

export const greenCornCeremony = {
  celebrationTitle: 'Green Corn Ceremony',
  startDate: '06-22',
  endDate: '07-31',
  filterValue: 'greenCornCeremony',
  celebrationImage: coverPhoto,
  displayDate: 'Late June-July',
  description: ' The Green Corn Ceremony is a multi-day festival celebrated by various Southeastern Native American tribes at the beginning of the corn harvest. The festival is a celebration of many types of new beginnings and includes camping on the land, dancing, working together to prepare the ceremony, and fasting.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/BwaKrhD0uAg',
      videoImage: concertThumbnail,
      songTitle: 'Social Dances',
      artistName: 'Martha',
      videoTags: [
        {
          tag: 'Green Corn Ceremony',
        },
      ],
      songDescription: 'These songs are three tribal social dances from the Cherokee and Choctaw tribes. Social dances are performed at powwows, gatherings that bring together members of different tribes. Because each tribe has its own language, the lyrics are  vocables— syllables like “la la la,” so everyone can sing together. The lyrics are accompanied by percussion, and the dance movements express the meaning of the dance.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Martha',
      artistId: 'martha',
      artistImage: marthaImage,
    },
  ],
};
