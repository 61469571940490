import coverPhoto from '../assets/hotspot/celebrationCover/carnival-1.jpg';
import concertThumbnail from '../assets/hotspot/celebrationPerformances/fabiana-requebra.png';
import fabianaImage from '../assets/hotspot/headerImages/fabiana-l.jpg';

export const brazilianCarnival = {
  celebrationTitle: 'Brazilian Carnival',
  startDate: '02-28',
  endDate: '03-05',
  filterValue: 'brazilianCarnival',
  celebrationImage: coverPhoto,
  displayDate: 'Dates vary each year',
  description: 'Carnival is celebrated all over the world with street parties, parades, music, and dancing. Some of the most well known Carnival celebrations in the world are in Brazil and Trinidad.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/irDaDJfymJE',
      videoImage: concertThumbnail,
      songTitle: 'Requebra',
      artistName: 'Fabiana',
      videoTags: [
        {
          tag: 'Brazilian Carnival',
        },
      ],
      songDescription: '“Requebra” was written by the samba school Olodum in Bahia, Brazil. In the middle of the 1980s, the group’s head drummer, Neguinho do Samba, mixed Afro-Caribbean rhythms with the traditional Brazilian samba. The result was a new style of samba that came to be known as samba reggae, and it ultimately came to dominate the Bahian Carnival.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Fabiana',
      artistId: 'fabiana',
      artistImage: fabianaImage,
    },
  ],
};
