// @flow

import React from 'react';
import { CelebrationPill } from '../CelebrationPill';
import { ListItem } from '../Filter/CelebrationsFilter/style';

type CelebrationItemProps = {
    item: {
      celebrationTitle: string,
      startDate?: string,
      endDate?: string,
      filterValue: string,
      displayDate?: string,
    },
    handleFilterClick: Function,
    isSelected: boolean,
    happeningNow: boolean,
  }

const CelebrationItem = ({
  item, handleFilterClick, isSelected, happeningNow,
}: CelebrationItemProps) => (
  <ListItem
    onClick={() => handleFilterClick(
      item.celebrationTitle,
      item.startDate,
      item.endDate,
      item.filterValue,
      item.displayDate,
    )}
  >
    <CelebrationPill
      celebrationTitle={item.celebrationTitle}
      startDate={item.startDate}
      endDate={item.endDate}
      isSelected={isSelected}
      filterValue={item.filterValue}
      happeningNow={happeningNow}
      displayDate={item.displayDate}
    />
  </ListItem>
);

export default CelebrationItem;
