import smallHeaderImage from '../assets/hotspot/headerImages/soul-science-lab-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/soul-science-lab-l.jpg';
import mus1 from '../assets/hotspot/pins/zulal.jpg';
import mus2 from '../assets/hotspot/pins/gregorio.jpg';
import introImage from '../assets/hotspot/intro/soul-science-lab.png';
import postcard from '../assets/hotspot/postcardPdf/Soul_Science_Lab_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/SoulScienceLabPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/ssl_concert.png';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/soul-science-hip-hop.png';

export const soulScienceLab = {
  name: 'Soul Science Lab',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Soul Science Lab, who sing Hip Hop music!',
    details: '',
    vimeoLink: 'https://vimeo.com/359801394',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=c0wRraErszg',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Soul-Science-Lab',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Soul Science Lab sing “Hip Hop Hooray”',
      link: 'Learn “Hip Hop Hooray”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Soul-Science-Lab/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/603905124%3Fsecret_token%3Ds-7XzCB&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Soul Science Lab sing “Higher”',
      link: 'Learn “Higher”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Soul-Science-Lab/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/603905100%3Fsecret_token%3Ds-bhWP5&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Soul Science Lab',
    artists: [
      {
        id: 1,
        image: mus1,
        name: 'Zulal',
        query: 'zulal',
      },
      {
        id: 2,
        image: mus2,
        name: 'Gregorio',
        query: 'gregorio',
      },
    ],
    linkOutText: 'Learn more about Soul Science Lab in Musical Explorers Program Five.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Five/',
  },
  traditionBlurb: 'Hip hop is the latest iteration of black music in America. Its roots lie in the ancient storytelling traditions of the West African griot, brought to the US during the trans-Atlantic slave trade. Over the centuries, the lineage of African American music grew to encompass spirituals, jazz, blues, rock, soul, R&B, and in the late 1970s, hip hop.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/nhQCR6mrAaw?si=HZ7KEnu0ovkViRTA',
      videoImage: celebrationThumbnail,
      songTitle: 'Hip Hop Hooray',
      artistName: 'Soul Science Day',
      videoTags: [
        {
          tag: 'Hip Hop Celebration Day',
        },
      ],
      songDescription: '"Hip Hop Hooray" is a famous hip hop song recorded by Naughty by Nature in 1992. Chen Lo and Asanté took the hook and underlying beat from this song and adapted it, adding their own lyrics. The lyrics acknowledge hip hop\'s roots in jazz, blues, and rock \'n\' roll, and social justice. ',
    },
  ],
};
