import coverPhoto from '../assets/hotspot/celebrationCover/haitian-independence.jpg';
import concertThumbnail from '../assets/hotspot/celebrationPerformances/emeline-panama-mwen.png';
import emelineImage from '../assets/hotspot/headerImages/emeline-l.jpg';

export const haitianIndependenceDay = {
  celebrationTitle: 'Haitian Independence Day',
  startDate: '01-01',
  endDate: '01-01',
  filterValue: 'haitianIndependenceDay',
  celebrationImage: coverPhoto,
  displayDate: 'January 1st',
  description: 'On January 1, 1804, Haiti gained independence from France. After 13 years fighting the Haitian Revolution, Haiti became the first independent Black nation in the western world.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/Oh9pcJiYnmc',
      videoImage: concertThumbnail,
      songTitle: 'Panama Mwen Tombe',
      artistName: 'Emeline',
      videoTags: [
        {
          tag: 'Haitian Independence Day',
        },
      ],
      songDescription: 'The song "Pana Mwen Tombe" features the tanbou drum, the national instrument of Haiti.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Emeline',
      artistId: 'emeline',
      artistImage: emelineImage,
    },
  ],
};
