import smallHeaderImage from '../assets/hotspot/headerImages/julia-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/julia-l.jpg';
import villalobosBrothersPin from '../assets/hotspot/pins/villalobos-brothers.jpg';
import etiennePin from '../assets/hotspot/pins/etienne.jpg';
import introImage from '../assets/hotspot/intro/julia.png';
import postcard from '../assets/hotspot/postcardPdf/Julia_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/JuliaPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/julia_concert.png';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/julia-cu-ti-lu-dissi.png';

export const julia = {
  name: 'Julia',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Julia, who sings Sicilian folk music!',
    details: '',
    vimeoLink: 'https://vimeo.com/501232437',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=tG8XHLqN5g8',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Eight/Julia',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Julia Sing “Rosa Canta e Cunta”',
      link: 'Learn “Rosa Canta e Cunta”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Eight/Julia/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/965567275%3Fsecret_token%3Ds-nRMCoeyUIZO&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Julia Sing “Cu Ti Lu Dissi”',
      link: 'Learn “Cu Ti Lu Dissi”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Eight/Julia/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/965567182%3Fsecret_token%3Ds-mWWFnDCntHK&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Julia appears on stage with',
    artists: [
      {
        id: 1,
        image: villalobosBrothersPin,
        name: 'Villalobos Brothers',
        query: 'villalobos-brothers',
      },
      {
        id: 2,
        image: etiennePin,
        name: 'Etienne',
        query: 'etienne',
      },
    ],
    linkOutText: 'Learn more about Julia in Musical Explorers Program Eight.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Eight',
  },
  traditionBlurb: 'Sicily, the “Island of the Sun,” was ruled by a succession of cultures and empires through several millennia,  including Greece, Phoenicia, Rome, Carthage, and Byzantium, as well as Muslim, Norman, and Spanish rulers, before becoming part of the country of Italy.  The result is a potent cultural melting pot, embodied in everything from Sicily’s unique language to its food and its folk music.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/xoR93RTXfK4',
      videoImage: celebrationThumbnail,
      songTitle: 'Cu Ti Lu Dissi',
      artistName: 'Julia',
      videoTags: [
        {
          tag: 'Valentine\'s Day',
        },
      ],
      songDescription: 'Julia says that there are no Sicilian love songs without heartbreak. Like most Sicilian folk songs, it is in a minor key. "Rosa Canta e Cunta" she says, is about choosing love over pain.',
    },
  ],
};
