import smallHeaderImage from '../assets/hotspot/headerImages/martha-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/martha-l.jpg';
import bongiTshidiPin from '../assets/hotspot/pins/bongi-tshidi.jpg';
import sofiaSofiaPin from '../assets/hotspot/pins/sofiar-sofiat.jpg';
import ilushaPin from '../assets/hotspot/pins/ilusha.jpg';
import introImage from '../assets/hotspot/intro/martha.png';
import postcard from '../assets/hotspot/postcardPdf/Martha_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/MarthaPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/martha_concert.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/martha-social-dances.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/martha-40-wheels.png';


export const martha = {
  name: 'Martha',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Martha, who sings Native American music!',
    vimeoLink: 'https://vimeo.com/252742962',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/BOHwnB_jXSQ',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Two/Martha',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Martha Sing “Social Dances”',
      link: 'Learn “Social Dances”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Two/Martha/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/874178155%3Fsecret_token%3Ds-NQrAMY3aPhL&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Martha Sing “40 Wheels”',
      link: 'Learn “40 Wheels”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Two/Martha/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/326227309%3Fsecret_token%3Ds-ef9W7&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'In Program Two, Martha appears on stage with',
    artists: [
      {
        id: 1,
        image: bongiTshidiPin,
        name: 'Bongi and Tshidi',
        query: 'bongi-tshidi',
      },
      {
        id: 2,
        image: sofiaSofiaPin,
        name: 'Sofía R. and Sofia T.',
        query: 'sofia-sofia',
      },
    ],
    linkOutText: 'Learn more about Martha in Musical Explorers Program Two.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Two/',
  },
  relatedArtist2: {
    title: 'In Program Seven, Martha appears on stage with',
    artists: [
      {
        id: 1,
        image: ilushaPin,
        name: 'Ilusha',
        query: 'ilusha',
      },
      {
        id: 2,
        image: bongiTshidiPin,
        name: 'Bongi and Tshidi',
        query: 'bongi-tshidi',
      },
    ],
    linkOutText: 'Learn more about Martha in Musical Explorers Program Seven',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Seven',
  },
  traditionBlurb: 'Martha Redbone’s Native American roots lie in the Southeastern region of the US, home to the Cherokee, Choctaw, and Shawnee tribes. The traditional music of these tribes is centered upon songs that accompany dances that continue to be performed at powwows and other social gatherings. The songs feature short sections of lyrics, often sung in call and response, accompanied by drums, rattles, whistles, pipes, and flutes.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/BwaKrhD0uAg',
      videoImage: celebrationThumbnail1,
      songTitle: 'Social Dances',
      artistName: 'Martha',
      videoTags: [
        {
          tag: 'Green Corn Ceremony',
        },
      ],
      songDescription: 'These songs are three tribal social dances from the Cherokee and Choctaw tribes. Social dances are performed at powwows, gatherings that bring together members of different tribes. Because each tribe has its own language, the lyrics are  vocables— syllables like “la la la,” so everyone can sing together. The lyrics are accompanied by percussion, and the dance movements express the meaning of the dance.',
    },
    {
      videoLink: 'https://youtu.be/s9LzYEC1ot8',
      videoImage: celebrationThumbnail2,
      songTitle: '40 Wheels',
      artistName: 'Martha',
      videoTags: [
        {
          tag: 'Native American Heritage Month',
        },
      ],
      songDescription: 'The song "40 Wheels" tells the story of the Trail of Tears, a very sad and difficult time in American history, when Southeastern Native American tribes were forced to leave their land and journey on foot to territory in Oklahoma.',
    },
  ],
};
