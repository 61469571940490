
import smallHeaderImage from '../assets/hotspot/headerImages/falu-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/falu-l.jpg';
import yacoubaPin from '../assets/hotspot/pins/yacouba.jpg';
import magdaPin from '../assets/hotspot/pins/magda.jpg';
import juanJuliaPin from '../assets/hotspot/pins/juan-julia.jpg';
import starrPin from '../assets/hotspot/pins/starr.jpg';
import introImage from '../assets/hotspot/intro/falu.png';
import postcard from '../assets/hotspot/postcardPdf/Falu_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/FaluPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/falu_concert.png';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/falu-dholida.png';

export const falu = {
  name: 'Falu',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Falu, who sings Indian Classical music!',
    vimeoLink: 'https://vimeo.com/291190906',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=CMjIFD1YD5g',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Three/Falu',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Falu Sing “Rabba”',
      link: 'Learn “Rabba”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Three/Falu/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/216882551%3Fsecret_token%3Ds-YkYi6&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Falu sing "Dholida"',
      link: 'Learn “Dholida”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Ten/Falu/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1082419243%3Fsecret_token%3Ds-SYR1KJTKgQo&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 3,
      type: 'audio',
      headline: 'Listen to Falu Sing “Allahoo”',
      link: 'Learn “Allahoo”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Three/Falu/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/425715498%3Fsecret_token%3Ds-5ao88&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'In Program Three, Falu appears on stage with',
    artists: [
      {
        id: 1,
        image: yacoubaPin,
        name: 'Yacouba',
        query: 'yacouba',
      },
      {
        id: 2,
        image: magdaPin,
        name: 'Magda',
        query: 'magda',
      },
    ],
    linkOutText: 'Learn more about Falu in Musical Explorers Program Three.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Three/',
  },
  relatedArtist2: {
    title: 'In NYC Spring 2022, Falu appears on stage with',
    artists: [
      {
        id: 1,
        image: juanJuliaPin,
        name: 'Juan and Julia',
        query: 'juan-julia',
      },
      {
        id: 2,
        image: starrPin,
        name: 'Starr',
        query: 'starr',
      },
    ],
    linkOutText: 'Learn more about Falu in Musical Explorers NYC Spring 2022',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/',
  },
  traditionBlurb: 'Indian classical music has two main traditions: Hindustani from the north and Carnatic from the south. Both traditions are based on the concepts of raga and tala. Raga is the melodic structure while encompasses meter and rhythm.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/XFKLL-exPiI',
      videoImage: celebrationThumbnail,
      songTitle: 'Dholida',
      artistName: 'Falu',
      videoTags: [
        {
          tag: 'Navaratri',
        },
      ],
      songDescription: 'The song “Dholida” is from Gujarat, a western state of India, and is sung in Gujarati, the language of the state. The song is commonly performed during the annual festival Navaratri.  The song “Dholida” celebrates the mother goddess Durga coming down, enjoying the celebration, and giving blessings.',
    },
  ],
};
