import smallHeaderImage from '../assets/hotspot/headerImages/sofia-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/sofia-l.jpg';
import bongiTshidiPin from '../assets/hotspot/pins/bongi-tshidi.jpg';
import marthaPin from '../assets/hotspot/pins/martha.jpg';
import introImage from '../assets/hotspot/intro/sofiar-sofiat.png';
import postcard from '../assets/hotspot/postcardPdf/Sofia_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/SofiaR_TPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/sofias_concert.png';

export const sofia = {
  name: 'Sofía R. and Sofia T.',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Sofía R. and Sofia T., who sing Argentine folk music!',
    details: '',
    vimeoLink: 'https://vimeo.com/252743866',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/n5VF3o2rh4o',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Two/Sofia-R-and-Sofia-T',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Sofía R. and Sofia T. Sing “La Cocinerita”',
      link: 'Learn “La Cocinerita”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Two/Sofia-R-and-Sofia-T/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/326227347%3Fsecret_token%3Ds-S0KUb&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Sofía R. and Sofia T. Sing “Chacarera del Rancho”',
      link: 'Learn “Chacarera del Rancho”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Two/Sofia-R-and-Sofia-T/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/326227337%3Fsecret_token%3Ds-HoagD&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Sofía R. and Sofia T. appear on stage with',
    artists: [
      {
        id: 1,
        image: bongiTshidiPin,
        name: 'Bongi and Tshidi',
        query: 'bongi-tshidi',
      },
      {
        id: 2,
        image: marthaPin,
        name: 'Martha',
        query: 'martha',
      },
    ],
    linkOutText: 'Learn more about Martha in Musical Explorers Program Two.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Two/',
  },
};
