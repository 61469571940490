import smallHeaderImage from '../assets/hotspot/headerImages/gregorio-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/gregorio-l.jpg';
import mus1 from '../assets/hotspot/pins/zulal.jpg';
import mus2 from '../assets/hotspot/pins/soul-science-lab.jpg';
import introImage from '../assets/hotspot/intro/gregorio.png';
import postcard from '../assets/hotspot/postcardPdf/Gregorio_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/GregorioPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/gregorio_concert.png';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/gregorio-la-piragua.png';

export const gregorio = {
  name: 'Gregorio',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Gregorio, who sings Cumbia music!',
    details: '',
    vimeoLink: 'https://vimeo.com/359801018',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=-9AGEVrQgC8',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Gregorio',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Gregorio sing “El Pescador”',
      link: 'Learn “El Pescador”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Gregorio/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/603905199%3Fsecret_token%3Ds-cYHpy&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Gregorio sing “La Piragua”',
      link: 'Learn “La Piragua”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Gregorio/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/603905136%3Fsecret_token%3Ds-8aSbb&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Gregorio appears on stage with',
    artists: [
      {
        id: 1,
        image: mus1,
        name: 'Zulal',
        query: 'zulal',
      },
      {
        id: 2,
        image: mus2,
        name: 'Soul Science Lab',
        query: 'soul-science-lab',
      },
    ],
    linkOutText: 'Learn more about Gregorio in Musical Explorers Program Five.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Five/',
  },
  traditionBlurb: 'Colombia has been called “the land of a thousand rhythms,” and cumbia is one of the most ubiquitous and enduring of these rhythms. The genre brings together African, indigenous, and, to a lesser extent, Spanish influences.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/gmlHV2kBwpE?si=HeJXnarXgl2HHJTM',
      videoImage: celebrationThumbnail,
      songTitle: 'La Piragua',
      artistName: 'Gregorio',
      videoTags: [
        {
          tag: 'Cumbia Day',
        },
      ],
      songDescription: 'José Benito Barros is perhaps the most well-known and prolific of Colombian composers, having written more than 800 songs! “La Piragua” is one of his most famous. The song features the three layered rhythms that are known as a basic cumbia rhythm, and there\'s a whole dance that goes along with the music. ',
    },
  ],
};
