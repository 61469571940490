import concertThumbnail from '../assets/hotspot/celebrationPerformances/villalobos-son-jarocho.png';
import villalobosImage from '../assets/hotspot/headerImages/villalobos-brothers-l.jpg';
import coverPhoto from '../assets/hotspot/celebrationCover/cinco-de-mayo.jpg';

export const cincoDeMayo = {
  celebrationTitle: 'Cinco de Mayo',
  startDate: '05-05',
  endDate: '05-05',
  filterValue: 'cincoDeMayo',
  celebrationImage: coverPhoto,
  displayDate: 'May 5th',
  description: 'Cinco de Mayo commemorates the Mexican army’s May 5, 1862 victory over France in the Franco-Mexican war. Within Mexico, Cinco de Mayo is primarily observed in the state of Puebla. In the US, many communities  celebrate the occasion with parades, parties, food, and folk dances.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/2nSN-gZeQMA?si=Q-KmS7DSbFG5J-jf',
      videoImage: concertThumbnail,
      songTitle: 'El Colás',
      artistName: 'Villalobos Brothers',
      videoTags: [
        {
          tag: 'Cinco de Mayo',
        },
      ],
      songDescription: '"El Colás" is a classic son jarocho song from Veracruz, Mexico. The song is traditionally danced by couples, often as part of fandangos. Fandangos are traditional celebrations from rural communities of southern Veracruz. They celebrate harvest cycles, religious holidays, and personal milestones.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Villalobos Brothers',
      artistId: 'villalobos-brothers',
      artistImage: villalobosImage,
    },
  ],
};
