import smallHeaderImage from '../assets/hotspot/headerImages/van-anh-s.png';
import largeHeaderImage from '../assets/hotspot/headerImages/van-anh-l.png';
import introImage from '../assets/hotspot/intro/vananh.png';
import postcard from '../assets/hotspot/postcardPdf/Van_Anh_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/VanAnhPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/vananh_concert.png';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/van-anh-co-doi.png';

export const vanAnh = {
  name: 'Vân-Ánh',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Vân-Ánh, who sings Vietnamese Folk music!',
    details: '',
    vimeoLink: 'https://vimeo.com/789100364',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/jDsP_oNBFYM',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Van-Anh',
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Vân-Ánh sing “Hò Hụi Huế”',
      link: 'Learn “Hò Hụi Huế”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Van-Anh/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1605373116%3Fsecret_token%3Ds-bsfjAxRWvCf&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Vân-Ánh sing “Cô Đôi Thượng Ngàn”',
      link: 'Learn “Cô Đôi Thượng Ngàn”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Van-Anh/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1427557039%3Fsecret_token%3Ds-KQBHzBnYtgB&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  traditionBlurb: 'Vietnamese folk songs tell stories of life and traditions. Each song has a beautiful musical story of the Vietnamese people, dating back 4,000 years old. Originating in the north, they spread as the kingdom expanded southward. Having a tonal language, the music of the various regions of Vietnam are distinguished by the subtle differences in the accents, grace notes, and use of vibrato.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/Wh48vzIbKzo',
      videoImage: celebrationThumbnail,
      songTitle: 'Cô Đôi Thượng Ngàn',
      artistName: 'Vân-Ánh',
      videoTags: [
        {
          tag: 'Lunar New Year',
        },
      ],
      songDescription: '“Cô Đôi Thượng Ngàn” is a Vietnamese folk song performed at ceremonies and festivals such as New Year\'s celebrations. It\'s a folk song that celebrates the Queen of the Forest who rules all of the land. The queen is an immortal character living on top of mountains and forests, known for her magical ability to take care of those who suffered from sickness.',
    },
  ],
};
