import concertThumbnail from '../assets/hotspot/celebrationPerformances/falu-dholida.png';
import faluImage from '../assets/hotspot/headerImages/falu-l.jpg';
import coverPhoto from '../assets/hotspot/celebrationCover/navaratri.png';

export const navaratri = {
  celebrationTitle: 'Navaratri',
  startDate: '10-03',
  endDate: '10-12',
  filterValue: 'navaratri',
  celebrationImage: coverPhoto,
  displayDate: 'Dates vary each year',
  description: 'Navaratri is a festival in India that lasts for nine days. The festival is a dedication to Durga (sometimes known as Shakti or Devi), the mother goddess and protector of good and harmony who battles against evil.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/XFKLL-exPiI',
      videoImage: concertThumbnail,
      songTitle: 'Dholida',
      artistName: 'Falu',
      videoTags: [
        {
          tag: 'Navaratri',
        },
      ],
      songDescription: 'The song “Dholida” is from Gujarat, a western state of India, and is sung in Gujarati, the language of the state. The song is commonly performed during the annual festival Navaratri.  The song “Dholida” celebrates the mother goddess Durga coming down, enjoying the celebration, and giving blessings.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Falu',
      artistId: 'falu',
      artistImage: faluImage,
    },
  ],
};
