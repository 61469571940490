import smallHeaderImage from '../assets/hotspot/headerImages/brianna-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/brianna-l.jpg';
import juanJuliaPin from '../assets/hotspot/pins/juan-julia.jpg';
import fabianaPin from '../assets/hotspot/pins/fabiana.jpg';
import emelinePin from '../assets/hotspot/pins/emeline.jpg';
import tanyaradzwaPin from '../assets/hotspot/pins/tanyaradzwa.jpg';
import introImage from '../assets/hotspot/intro/brianna.png';
import postcard from '../assets/hotspot/postcardPdf/Brianna_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/BriannaPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/brianna_concert.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/brianna-blue-skies.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/brianna-a-train.png';

export const brianna = {
  name: 'Brianna',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Brianna, who sings jazz!',
    vimeoLink: 'https://vimeo.com/313666563',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/V8AipwmDrd4',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Four/Brianna',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Brianna Sing “Blue Skies”',
      link: 'Learn “Blue Skies”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Four/Brianna/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/216882472%3Fsecret_token%3Ds-sQeNS&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Brianna Sing “Take the A Train”',
      link: 'Learn “Take the A Train”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Four/Brianna/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/216882464%3Fsecret_token%3Ds-KFGsx&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'In Program Four, Brianna appears on stage with',
    artists: [
      {
        id: 1,
        image: juanJuliaPin,
        name: 'Juan and Julia ',
        query: 'juan-julia',
      },
      {
        id: 2,
        image: fabianaPin,
        name: 'Fabiana',
        query: 'fabiana',
      },
    ],
    linkOutText: 'Learn more about Brianna in Musical Explorers Program Four.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Four/',
  },
  relatedArtist2: {
    title: 'In NYC Fall 2021, Brianna appears on stage with',
    artists: [
      {
        id: 1,
        image: emelinePin,
        name: 'Emeline',
        query: 'emeline',
      },
      {
        id: 2,
        image: tanyaradzwaPin,
        name: 'Tanyaradzwa Uzuri ',
        query: 'tanyaradzwa',
      },
    ],
    linkOutText: 'Learn more about Brianna in Musical Explorers NYC Fall 2021.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/',
  },
  traditionBlurb: 'Jazz is a hybrid created by the convergence in North America of African traditions brought predominantly by enslaved people with western European traditions brought by free colonists that specifically nurtured the ethnic and cultural melting pot that became New Orleans at the turn of the 20th century.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/pi70_yRwxGw',
      videoImage: celebrationThumbnail1,
      songTitle: 'Blue Skies',
      artistName: 'Brianna',
      videoTags: [
        {
          tag: 'Jazz Appreciation Month',
        },
      ],
      songDescription: 'Irving Berlin (1888–1989) is considered one of the greatest songwriters in American history.  Berlin wrote more than 1,500 songs, including hundreds of hits. “Blue Skies” was composed as part of a musical called Betsy. Audiences loved the song so much they demanded 24 encores of it on the show’s opening night!',
    },
    {
      videoLink: 'https://youtu.be/DvGVFcepJ-g',
      videoImage: celebrationThumbnail2,
      songTitle: 'Take the A Train',
      artistName: 'Brianna',
      videoTags: [
        {
          tag: 'Jazz Appreciation Month',
        },
      ],
      songDescription: 'A wonderful introduction to jazz, “Take the A Train” features an iconic melody and a crystal-clear view into a specific place and time. After Duke Ellington hired Billy Strayhorn and wrote him directions to his house by subway, Strayhorn composed the legendary tune, and it became a staple of the Duke Ellington Orchestra. ',
    },
  ],
};
