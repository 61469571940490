import smallHeaderImage from '../assets/hotspot/headerImages/etienne-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/etienne-l.jpg';
import villalobosBrothersPin from '../assets/hotspot/pins/villalobos-brothers.jpg';
import juliaPin from '../assets/hotspot/pins/julia.jpg';
import introImage from '../assets/hotspot/intro/etienne.png';
import postcard from '../assets/hotspot/postcardPdf/Etienne_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/EtiennePostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/etienne_concert.png';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/etienne-jouvert.png';

export const etienne = {
  name: 'Etienne',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Etienne, who sings calypso!',
    details: '',
    vimeoLink: 'https://vimeo.com/204216501',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/pak2egltKas',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Eight/Etienne',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Etienne Sing “Mary Ann”',
      link: 'Learn “Mary Ann”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Eight/Etienne/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/277715103%3Fsecret_token%3Ds-8eIVp&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Etienne Sing “J’ouvert Barrio”',
      link: 'Learn “J’ouvert Barrio”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Eight/Etienne/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/277715097%3Fsecret_token%3Ds-Hzjer&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Etienne appears on stage with ',
    artists: [
      {
        id: 1,
        image: villalobosBrothersPin,
        name: 'Villalobos Brothers',
        query: 'villalobos-brothers',
      },
      {
        id: 2,
        image: juliaPin,
        name: 'Julia',
        query: 'julia',
      },
    ],
    linkOutText: 'Learn more about Etienne in Musical Explorers Program Eight.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Eight',
  },
  traditionBlurb: 'Calypso traces its roots to West Africa in the 1600s, drawing on traditions of social commentary and improvisational lyrics in song. Modern calypso was born in Trinidad and Tobago in the early 1900s, when these West African traditions mixed with chantuelle, a Creole vocal tradition that accompanied stick fights known as kalenda.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/bt4kqjbNg-4',
      videoImage: celebrationThumbnail,
      songTitle: 'J’ouvert Barrio',
      artistName: 'Etienne',
      videoTags: [
        {
          tag: 'Trinidad and Tobago Carnival',
        },
      ],
      songDescription: ' The word "J\'ouvert" means "daybreak" in Creole, and the song "J\'ouvert Barrio" is sung at the beginning of Carnival.  Many people wear elaborate, colorful costumes that depict traditional Carnival characters, including the Sailor, Dragon, Bat, Pierrot (or clown), Cow, Jab Molassie (or devil), and Moko Jumbie (or stilt walker). ',
    },
  ],
};
