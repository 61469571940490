
import smallHeaderImage from '../assets/hotspot/headerImages/juan-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/juan-l.jpg';
import briannaPin from '../assets/hotspot/pins/brianna.jpg';
import fabianaPin from '../assets/hotspot/pins/fabiana.jpg';
import faluPin from '../assets/hotspot/pins/falu.jpg';
import starrPin from '../assets/hotspot/pins/starr.jpg';
import introImage from '../assets/hotspot/intro/juan-julia.png';
import postcard from '../assets/hotspot/postcardPdf/Juan_and_Julia_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/JuanJuliaPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/juan_julia_concert.png';

export const juan = {
  name: 'Juan and Julia',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Juan and Julia, who sing bomba and plena!',
    vimeoLink: 'https://vimeo.com/313668655',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=e3OQ3hJa-0k',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Four/Juan-and-Julia',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Juan and Julia sing "Estoy Buscando un Árbol"',
      link: 'Learn “Estoy Buscando un Árbol”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Four/Juan-and-Julia/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/425777931%3Fsecret_token%3Ds-i8OTj&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Juan and Julia sing "Ola de la Mar"',
      link: 'Learn “Ola de la Mar”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Four/Juan-and-Julia/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/425778837%3Fsecret_token%3Ds-fARw9&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'In Program Four, Juan and Julia appear on stage with',
    artists: [
      {
        id: 1,
        image: briannaPin,
        name: 'Brianna',
        query: 'brianna',
      },
      {
        id: 2,
        image: fabianaPin,
        name: 'Fabiana',
        query: 'fabiana',
      },
    ],
    linkOutText: 'Learn more about Juan and Julia in Musical Explorers Program Four.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Four',
  },
  relatedArtist2: {
    title: 'In NYC Spring 2022, Juan and Julia appear on stage with',
    artists: [
      {
        id: 1,
        image: faluPin,
        name: 'Falu',
        query: 'falu',
      },
      {
        id: 2,
        image: starrPin,
        name: 'Starr',
        query: 'starr',
      },
    ],
    linkOutText: 'Learn more about Juan and Julia in Musical Explorers NYC Spring 2022.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City',
  },
};
