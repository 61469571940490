// @flow

import React, { useState, useRef, useEffect } from 'react';
import { CELEBRATIONS_FILTERS } from '../../../constants/celebrationsFilters';

import {
  FilterContainer,
  FilterToggle,
  FilterToggleText,
  FilterToggleIcon,
  FilterListContainer,
  FilterSection,
  Section,
  SectionHeadingContainer,
  List,
  HappeningNowSection,
  HappeningNowHeading,
  ListWrappers,
  SectionDateRange,
  SectionHeading,
  SectionDateWrapper,
} from './style';

import CelebrationItem from '../../CelebrationsListItem';
import { getFilterSections } from '../../../utils/getFilterSections';
import { getFeaturedEvents } from '../../../utils/getFeaturedEvents';
import { CloseFilter } from '../../../assets/icons/closeFilter';
import { OpenFilter } from '../../../assets/icons/openFilter';
import { FeaturedCelebrationCard } from '../../FeaturedCelebrationCard';

type Props = {
  selectedFilter: {
    celebrationTitle: string,
    startDate?: string,
    endDate?: string,
    filterValue: string,
  },
  setSelectedFilter: Function,
  resetTraditionsFilter: Function,
};

export const CelebrationsFilter = ({
  selectedFilter,
  setSelectedFilter,
  resetTraditionsFilter,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSection, setOpenSection] = useState(-1);
  const toggleRef = useRef(null);
  const filterListRef = useRef(null);

  const clickOutsideHandler = (event) => {
    // $FlowFixMe
    if (filterListRef.current.contains(event.target) || toggleRef.current.contains(event.target)) {
      return;
    }
    setIsOpen();
  };

  useEffect(() => {
    if (isOpen) {
      // $FlowFixMe
      document.addEventListener('mouseup', clickOutsideHandler);
      // $FlowFixMe
    } else {
      // $FlowFixMe
      document.removeEventListener('mouseup', clickOutsideHandler);
    }

    return () => {
      // $FlowFixMe
      document.removeEventListener('mouseup', clickOutsideHandler);
    };
  }, [isOpen]);

  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterClick = (celebrationTitle, startDate, endDate, filterValue, displayDate) => {
    setSelectedFilter({
      celebrationTitle, startDate, endDate, filterValue, displayDate,
    });
    resetTraditionsFilter();
  };

  const wrapKeyHandler = (event) => {
    if (event.key === 'Escape' && isOpen) {
      // escape key
      setIsOpen(false);
      // $FlowFixMe
      toggleRef.current.focus();
    }
  };

  const setActiveSection = (index) => {
    if (openSection === index) {
      setOpenSection(null);
    } else {
      setOpenSection(index);
    }
  };

  return (
    <FilterContainer onKeyUp={wrapKeyHandler}>
      <FilterToggle
        aria-haspopup='menu'
        aria-controls='celebrations-filter'
        onClick={handleToggleClick}
        // $FlowFixMe
        ref={toggleRef}
        isOpen={isOpen}
        aria-expanded={isOpen ? 'true' : 'false'}
      >
        <FilterToggleText>Celebrations</FilterToggleText>
        <FilterToggleIcon
          xmlns='http://www.w3.org/2000/svg'
          width='12'
          height='7'
          fill='none'
          viewBox='0 0 12 7'
          strokeWidth={1.5}
          isOpen={isOpen}
        >
          <path stroke='#fff' d='M1 1l5 5 5-5' />
        </FilterToggleIcon>
      </FilterToggle>
      {isOpen && (
        <FilterListContainer ref={filterListRef} className='celebration'>
          <Section>
            <SectionHeadingContainer onClick={() => setActiveSection(-1)}>
              <SectionDateWrapper>
                <SectionHeading>Featured</SectionHeading>
                <SectionDateRange className='featured'>
                  Happening Now
                </SectionDateRange>
              </SectionDateWrapper>
              {openSection === -1 ? (
                <CloseFilter />
              ) : (
                <OpenFilter />
              )}
            </SectionHeadingContainer>
            {openSection === -1 && (
            <ListWrappers>
              <List className='featured'>
                {getFeaturedEvents(CELEBRATIONS_FILTERS).map((item) => (
                  <FeaturedCelebrationCard
                    celebrationTitle={item.celebrationTitle}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    displayDate={item.displayDate}
                    celebrationImage={item.celebrationImage}
                    filterValue={item.filterValue}
                    handleFilterClick={handleFilterClick}
                  />
                ))}
              </List>
            </ListWrappers>
            )}
          </Section>
          <FilterSection>
            {CELEBRATIONS_FILTERS.map((filter, index) => {
              const { happening, upcoming } = getFilterSections(filter.filterItems);
              return (
                <Section key={filter.sectionTitle} className={openSection === index ? 'active' : ''}>
                  <SectionHeadingContainer onClick={() => setActiveSection(index)}>
                    <SectionDateWrapper>
                      <SectionHeading>{filter.sectionTitle}</SectionHeading>
                      <SectionDateRange>
                        {filter.sectionDateRange}
                      </SectionDateRange>
                    </SectionDateWrapper>
                    {openSection === index ? (
                      <CloseFilter />
                    ) : (
                      <OpenFilter />
                    )}
                  </SectionHeadingContainer>
                  <ListWrappers>
                    {openSection === index && (
                      <>
                        <List>
                          {upcoming.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
                            .map((item) => (
                              <CelebrationItem
                                key={item.celebrationTitle}
                                item={item}
                                handleFilterClick={handleFilterClick}
                                isSelected={
                                  selectedFilter.celebrationTitle === item.celebrationTitle
                                }
                                happeningNow={false}
                              />
                            ))}
                        </List>
                          {happening.length > 0 && (
                            <HappeningNowSection>
                              <HappeningNowHeading><span>Happening Now</span></HappeningNowHeading>
                              <List>
                                {happening.map((item) => (
                                  <CelebrationItem
                                    key={item.celebrationTitle}
                                    item={item}
                                    handleFilterClick={handleFilterClick}
                                    isSelected={
                                      selectedFilter.celebrationTitle === item.celebrationTitle
                                    }
                                    happeningNow
                                  />
                                ))}
                              </List>
                            </HappeningNowSection>
                          )}
                      </>
                    )}
                  </ListWrappers>
                </Section>
              );
            })}
          </FilterSection>
        </FilterListContainer>
      )}
    </FilterContainer>
  );
};
