import coverPhoto from '../assets/hotspot/celebrationCover/arab-american-history.jpg';
import concertThumbnail from '../assets/hotspot/celebrationPerformances/farah-reedaha.png';
import farahImage from '../assets/hotspot/headerImages/farah-l.png';

export const arabAmericanHistoryMonth = {
  celebrationTitle: 'Arab-American History Month',
  startDate: '04-01',
  endDate: '04-30',
  filterValue: 'arabAmericanHistoryMonth',
  celebrationImage: coverPhoto,
  displayDate: 'The month of April',
  description: 'Arab-American History Month is celebrated in April and recognizes the significant Arab population in America, as well as the cultural contributions of Arab Americans.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/sHtuXCGTX-8',
      videoImage: concertThumbnail,
      songTitle: 'Reedaha / Ya Khayal il Zarga',
      artistName: 'Farah',
      videoTags: [
        {
          tag: 'Arab-American History Month',
        },
      ],
      songDescription: '"Reedaha" is a very old song passed orally thorugh generations, and this one folds in the song "Ya Khayal il Zarga." Both songs are ghazals, a type of love song that might express flirtation, longing, or a loss of love.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Farah',
      artistId: 'farah',
      artistImage: farahImage,
    },
  ],
};
