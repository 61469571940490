import smallHeaderImage from '../assets/hotspot/headerImages/fabiana-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/fabiana-l.jpg';
import briannaPin from '../assets/hotspot/pins/brianna.jpg';
import juanJuliaPin from '../assets/hotspot/pins/juan-julia.jpg';
import introImage from '../assets/hotspot/intro/fabiana.png';
import postcard from '../assets/hotspot/postcardPdf/Fabiana_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/FabianaPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/fabiana_concert.png';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/fabiana-requebra.png';

export const fabiana = {
  name: 'Fabiana',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Fabiana, who sings Brazilian music!',
    details: '',
    vimeoLink: 'https://vimeo.com/313667501',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=AQXDs7kWplU',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Four/Fabiana',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Fabiana Sing “Mas Que Nada”',
      link: 'Learn “Mas Que Nada”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Four/Fabiana/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/425780655%3Fsecret_token%3Ds-rSsZx&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Fabiana Sing “Requebra”',
      link: 'Learn “Requebra”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Four/Fabiana/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/425781414%3Fsecret_token%3Ds-L1V0h&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Fabiana appears on stage with',
    artists: [
      {
        id: 1,
        image: briannaPin,
        name: 'Brianna',
        query: 'brianna',
      },
      {
        id: 2,
        image: juanJuliaPin,
        name: 'Juan and Julia',
        query: 'juan-julia',
      },
    ],
    linkOutText: 'Learn more about Fabiana in Musical Explorers Program Four.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Four/',
  },
  traditionBlurb: 'The many interconnected styles of Brazilian popular music emerged from different regions of the country, building on musical traditions brought to Brazil by enslaved Africans with European elements introduced by the Portuguese in colonial times.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/irDaDJfymJE',
      videoImage: celebrationThumbnail,
      songTitle: 'Requebra',
      artistName: 'Fabiana',
      videoTags: [
        {
          tag: 'Brazilian Carnival',
        },
      ],
      songDescription: '“Requebra” was written by the samba school Olodum in Bahia, Brazil. In the middle of the 1980s, the group’s head drummer, Neguinho do Samba, mixed Afro-Caribbean rhythms with the traditional Brazilian samba. The result was a new style of samba that came to be known as samba reggae, and it ultimately came to dominate the Bahian Carnival.',
    },
  ],
};
