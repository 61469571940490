import ilushaPin from '../assets/hotspot/pins/ilusha.png';
import imaniPin from '../assets/hotspot/pins/imani.png';
import sofiasofiaPin from '../assets/hotspot/pins/sofiar-sofiat.png';
import marthaPin from '../assets/hotspot/pins/martha.png';
import juanJuliaPin from '../assets/hotspot/pins/juan-julia.png';
import fabianaPin from '../assets/hotspot/pins/fabiana.png';
import briannaPin from '../assets/hotspot/pins/brianna.png';
import yacoubaPin from '../assets/hotspot/pins/yacouba.png';
import magdaPin from '../assets/hotspot/pins/magda.png';
import emelinePin from '../assets/hotspot/pins/emeline.png';
import faluPin from '../assets/hotspot/pins/falu.png';
import bongiTshidiPin from '../assets/hotspot/pins/bongi-tshidi.png';

import gregorioPin from '../assets/hotspot/pins/gregorio.png';
import zulalPin from '../assets/hotspot/pins/zulal.png';
import soulScienceLabPin from '../assets/hotspot/pins/soul-science-lab.png';
import michaelPin from '../assets/hotspot/pins/michael.png';
import qianYiPin from '../assets/hotspot/pins/qian-yi.png';
import laythPin from '../assets/hotspot/pins/layth.png';
import villalabosBrothersPin from '../assets/hotspot/pins/villalobos-brothers.png';
import juliaPin from '../assets/hotspot/pins/julia.png';
import etiennePin from '../assets/hotspot/pins/etienne.png';
import tanyaradzwaPin from '../assets/hotspot/pins/tanyaradzwa.png';
import starrPin from '../assets/hotspot/pins/starr.png';
import quianaPin from '../assets/hotspot/pins/quiana.png';
import farahPin from '../assets/hotspot/pins/farah.png';
import vananhPin from '../assets/hotspot/pins/van-anh.png';
import yasserPin from '../assets/hotspot/pins/yasser.png';
import kalaniPin from '../assets/hotspot/pins/kalani.png';


export const ARTISTS_MARKERS_ARRAY = [
  {
    name: 'ilusha',
    tradition: ['georgianFolk'],
    celebrations: ['easter', 'earthDay'],
    fullName: 'Ilusha',
    genre: 'Georgian Folk',
    markerFill: '#435AA2',
    image: ilushaPin,
    region: ['Georgia'],
  },
  {
    name: 'imani',
    tradition: ['freedomSongs'],
    celebrations: [],
    fullName: 'Imani Uzuri',
    genre: 'Freedom Songs',
    markerFill: '#7B2572',
    image: imaniPin,
    region: ['United States'],
  },
  {
    name: 'sofia-sofia',
    tradition: ['argentineFolk'],
    celebrations: [],
    fullName: 'Sofía R. and Sofia T.',
    genre: 'Argentine Folk',
    markerFill: '#DB4373',
    image: sofiasofiaPin,
    region: ['Argentina'],
  },
  {
    name: 'martha',
    tradition: ['nativeAmerican'],
    celebrations: ['greenCornCeremony', 'nativeAmericanHeritageMonth'],
    fullName: 'Martha',
    genre: 'Native American',
    markerFill: '#4899A8',
    image: marthaPin,
    region: ['Southeastern United States'],
  },
  {
    name: 'magda',
    tradition: ['greekFolk'],
    celebrations: [],
    fullName: 'Magda',
    genre: 'Greek Folk',
    markerFill: '#48A3AE',
    image: magdaPin,
    region: ['Greece'],
  },
  {
    name: 'yacouba',
    tradition: ['malianTraditional'],
    celebrations: [],
    fullName: 'Yacouba',
    genre: 'Malian Traditional',
    markerFill: '#DF893B',
    image: yacoubaPin,
    region: ['Mali'],
  },
  {
    name: 'falu',
    tradition: ['indianClassical'],
    celebrations: ['navaratri'],
    fullName: 'Falu',
    genre: 'Indian Classical',
    markerFill: '#D9659E',
    image: faluPin,
    region: ['India'],
  },
  {
    name: 'brianna',
    tradition: ['jazz'],
    celebrations: ['jazzAppreciationMonth'],
    fullName: 'Brianna',
    genre: 'Jazz',
    markerFill: '#F36C21',
    image: briannaPin,
    region: ['New York City', 'New Orleans'],
  },
  {
    name: 'michael',
    tradition: ['bluegrass'],
    celebrations: [],
    fullName: 'Michael',
    genre: 'Bluegrass',
    markerFill: '#124a84',
    image: michaelPin,
    region: ['Appalachia', 'British Isles'],
  },
  {
    name: 'quiana',
    tradition: ['gullahMusic'],
    celebrations: [],
    fullName: 'Quiana',
    genre: 'Gullah Music',
    markerFill: '#3562d9',
    image: quianaPin,
    region: ['South Carolina'],
  },
  {
    name: 'emeline',
    tradition: ['haitian'],
    celebrations: ['haitianIndependenceDay'],
    fullName: 'Emeline',
    genre: 'Haitian',
    markerFill: '#C7862A',
    image: emelinePin,
    region: ['Haiti'],
  },
  {
    name: 'juan-julia',
    tradition: ['bombaAndPlena'],
    celebrations: [],
    fullName: 'Juan and Julia',
    genre: 'Bomba and Plena',
    markerFill: '#30BC5C',
    image: juanJuliaPin,
    region: ['Puerto Rico', 'West Africa'],
  },
  {
    name: 'fabiana',
    tradition: ['brazilian'],
    celebrations: ['brazilianCarnival'],
    fullName: 'Fabiana',
    genre: 'Brazilian',
    markerFill: '#2A408D',
    image: fabianaPin,
    region: ['Brazil', 'West Africa'],
  },
  {
    name: 'gregorio',
    tradition: ['cumbia'],
    celebrations: ['cumbiaDay'],
    fullName: 'Gregorio',
    genre: 'Cumbia',
    markerFill: '#008d7e',
    image: gregorioPin,
    region: ['Colombia'],
  },
  {
    name: 'zulal',
    tradition: ['armenianFolk'],
    celebrations: [],
    fullName: 'Zulal',
    genre: 'Armenian Folk',
    markerFill: '#f2a418',
    image: zulalPin,
    region: ['Armenia'],
  },
  {
    name: 'soul-science-lab',
    tradition: ['hipHop'],
    celebrations: ['hipHopCelebrationDay'],
    fullName: 'Soul Science Lab',
    genre: 'Hip Hop',
    markerFill: '#89459b',
    image: soulScienceLabPin,
    region: ['New York City', 'Jamaica', 'Trinidad', 'West Africa'],
  },
  {
    name: 'qian-yi',
    tradition: ['chineseTraditional'],
    celebrations: ['lunarNewYear'],
    fullName: 'Qian Yi',
    genre: 'Chinese Traditional',
    markerFill: '#91bb3e',
    image: qianYiPin,
    region: ['China'],
  },
  {
    name: 'layth',
    tradition: ['iraqiFolk'],
    celebrations: [],
    fullName: 'Layth',
    genre: 'Iraqi Folk',
    markerFill: '#e3517f',
    image: laythPin,
    region: ['Iraq'],
  },
  {
    name: 'julia',
    tradition: ['sicilianFolk'],
    celebrations: ['valentinesDay'],
    fullName: 'Julia',
    genre: 'Sicilian Folk',
    markerFill: '#5451a2',
    image: juliaPin,
    region: ['Sicily'],
  },
  {
    name: 'etienne',
    tradition: ['calypso'],
    celebrations: ['trinidadTobagoCarnival'],
    fullName: 'Etienne',
    genre: 'Calypso',
    markerFill: '#601c34',
    image: etiennePin,
    region: ['Trinidad and Tobago'],
  },
  {
    name: 'tanyaradzwa',
    tradition: ['zimbabweanMbiraMusic'],
    celebrations: [],
    fullName: 'Tanyaradzwa',
    genre: 'Zimbabwean Mbira Music',
    markerFill: '#007FB1',
    image: tanyaradzwaPin,
    region: ['Zimbabwe'],
  },
  {
    name: 'bongi-tshidi',
    tradition: ['southAfricanZulu'],
    celebrations: ['heritageDay'],
    fullName: 'Bongi and Tshidi',
    genre: 'South African Zulu',
    markerFill: '#6AB257',
    image: bongiTshidiPin,
    region: ['South Africa'],
  },
  {
    name: 'starr',
    tradition: ['freedomSongs'],
    celebrations: [],
    fullName: 'Starr',
    genre: 'Freedom Songs',
    markerFill: '#3562d9',
    image: starrPin,
    region: ['United States'],
  },
  {
    name: 'villalobos-brothers',
    tradition: ['sonJarocho'],
    celebrations: ['cincoDeMayo'],
    fullName: 'Villalobos Brothers',
    genre: 'Son Jarocho',
    markerFill: '#6AB257',
    image: villalabosBrothersPin,
    region: ['Mexico'],
  },
  {
    name: 'farah',
    fullName: 'Farah',
    tradition: ['jordanianFolk'],
    genre: 'Jordanian Folk',
    markerFill: '#3562d9',
    image: farahPin,
    region: ['Jordan'],
    celebrations: ['jordanianIndependenceDay', 'arabAmericanHistoryMonth'],
  },
  {
    name: 'vanAnh',
    fullName: 'Vân-Ánh',
    tradition: ['vietnameseFolk'],
    genre: 'Vietnamese Folk',
    markerFill: '#3562d9',
    image: vananhPin,
    region: ['Vietnam'],
    celebrations: ['lunarNewYear'],
  },
  {
    name: 'yasser',
    fullName: 'Yasser',
    tradition: ['dominicanRoots'],
    genre: 'Dominican Roots',
    markerFill: '#3562d9',
    image: yasserPin,
    region: ['Dominican Republic'],
    celebrations: ['dominicanIndependenceDay'],
  },
  {
    name: 'kalani',
    fullName: 'Kalani',
    tradition: ['meleHawaii'],
    genre: 'Mele Hawai‘i',
    markerFill: '#3562d9',
    image: kalaniPin,
    region: ['Hawai‘i'],
    celebrations: ['merrieMonarchFestival'],
  },
];
