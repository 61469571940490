import coverPhoto from '../assets/hotspot/celebrationCover/native-american-heritage.jpg';
import concertThumbnail from '../assets/hotspot/celebrationPerformances/martha-40-wheels.png';
import marthaImage from '../assets/hotspot/headerImages/martha-l.jpg';

export const nativeAmericanHeritageMonth = {
  celebrationTitle: 'Native American Heritage Month',
  startDate: '11-01',
  endDate: '11-30',
  filterValue: 'nativeAmericanHeritageMonth',
  celebrationImage: coverPhoto,
  displayDate: 'The month of November',
  description: 'Native American Heritage Month is celebrated every November and honors the traditions, stories, and languages of Native American communities, ensuring that the culture and history continue to thrive in each passing generation.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/s9LzYEC1ot8',
      videoImage: concertThumbnail,
      songTitle: '40 Wheels',
      artistName: 'Martha',
      videoTags: [
        {
          tag: 'Native American Heritage Month',
        },
      ],
      songDescription: 'The song "40 Wheels" tells the story of the Trail of Tears, a very sad and difficult time in American history, when Southeastern Native American tribes were forced to leave their land and journey on foot to territory in Oklahoma.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Martha',
      artistId: 'martha',
      artistImage: marthaImage,
    },
  ],
};
