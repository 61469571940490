// @flow

import React from 'react';
import {
  CardContainer, CardDate, CardImage, CardImageContainer, CardTitle, TextContainer,
} from './style';


type Props = {
  celebrationTitle: string,
  startDate: string,
  endDate: string,
  displayDate: string,
  filterValue: string,
  celebrationImage: string,
  handleFilterClick: Function,
};

export const FeaturedCelebrationCard = ({
  celebrationTitle,
  startDate,
  endDate,
  filterValue,
  celebrationImage,
  handleFilterClick,
  displayDate,
}: Props) => (
  <CardContainer onClick={() => handleFilterClick(
    celebrationTitle,
    startDate,
    endDate,
    filterValue,
    displayDate,
  )}
  >
    <CardImageContainer>
      <CardImage src={celebrationImage} alt={celebrationTitle} />
    </CardImageContainer>
    <TextContainer>
      <CardTitle>{celebrationTitle}</CardTitle>
      <CardDate>{displayDate}</CardDate>
    </TextContainer>
  </CardContainer>
);
