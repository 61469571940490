import smallHeaderImage from '../assets/hotspot/headerImages/yacouba-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/yacouba-l.jpg';
import faluPin from '../assets/hotspot/pins/falu.jpg';
import magdaPin from '../assets/hotspot/pins/magda.jpg';
import introImage from '../assets/hotspot/intro/yacouba.png';
import postcard from '../assets/hotspot/postcardPdf/Yacouba_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/YacoubaPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/yacouba_concert.png';

export const yacouba = {
  name: 'Yacouba',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Yacouba, who sings Malian traditional music!',
    details: '',
    vimeoLink: 'https://vimeo.com/291190253',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=WOfvoRx4tGY',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Three/Yacouba',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Yacouba Sing “Kelefaba”',
      link: 'Learn “Kelefaba”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Three/Yacouba/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/216882573%3Fsecret_token%3Ds-E1oiL&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Yacouba Sing “Wawanko”',
      link: 'Learn “Wawanko”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Three/Yacouba/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/216882566%3Fsecret_token%3Ds-BNl1I&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Yacouba appears on stage with',
    artists: [
      {
        id: 1,
        image: magdaPin,
        name: 'Magda',
        query: 'magda',
      },
      {
        id: 2,
        image: faluPin,
        name: 'Falu',
        query: 'falu',
      },
    ],
    linkOutText: 'Learn more about Yacouba in Musical Explorers Program Three.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Three/',
  },
};
