// @flow

import React, { useEffect, useState } from 'react';
import {
  HeaderImage,
  HeaderImageContainer,
  TitleBanner,
  CelebrationTitle,
  DateRange,
  Description,
  ArtistHeadline,
  ArtistsContainer,
  ArtistImage,
  ArtistInfo,
  ArtistName,
  CelebrationsContainer,
  PerformanceHeadline,
  VideoContainer,
  VideoTags,
  Tag,
  ArrowButton,
  SongDescription,
} from './style';

import { HotspotVideo } from '../HotspotVideo';
import { celebrationDataMap } from '../../constants/celebrationDataMap';


type Props = {
  selectedCelebration: Object,
  handleNextCelebration: Function,
  handlePrevCelebration: Function,
  setActiveArtist: Function,
};


export const CelebrationHotspot = ({
  selectedCelebration,
  handleNextCelebration,
  handlePrevCelebration,
  setActiveArtist,
}: Props) => {
  const [activeData, setActiveData] = useState(null);

  useEffect(() => {
    if (selectedCelebration && selectedCelebration.filterValue) {
      const celebrationData = celebrationDataMap[selectedCelebration.filterValue];
      setActiveData(celebrationData);
    }
  }, [selectedCelebration]);


  if (!activeData) {
    return null;
  }

  return (
    <>
      <HeaderImageContainer>
        <HeaderImage src={activeData.celebrationImage} alt='Celebration' />
      </HeaderImageContainer>
      <TitleBanner>
        <ArrowButton onClick={handlePrevCelebration} type='button'>
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <path d='M16 4L8 12L16 20' stroke='white' strokeWidth='2' />
          </svg>
        </ArrowButton>
        <div>
          <CelebrationTitle>
            {selectedCelebration.celebrationTitle}
          </CelebrationTitle>
          <DateRange>{activeData.displayDate}</DateRange>
        </div>
        <ArrowButton onClick={handleNextCelebration} type='button'>
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <path d='M8 20L16 12L8 3.99997' stroke='white' strokeWidth='2' />
          </svg>
        </ArrowButton>
      </TitleBanner>
      <Description>{activeData.description}</Description>
      {activeData.artistsWhoCelebrate && (
        <>
          <ArtistHeadline>Artists who celebrate</ArtistHeadline>
          <ArtistsContainer>
            {activeData.artistsWhoCelebrate.map((artist) => (
              <ArtistInfo onClick={() => {
                if (window.innerWidth <= 720) {
                  setActiveArtist(artist.artistId);
                }
              }}
              >
                <ArtistImage src={artist.artistImage} alt={artist.artistName} />
                <ArtistName>{artist.artistName}</ArtistName>
              </ArtistInfo>
            ))}
          </ArtistsContainer>
        </>
      )}
      <CelebrationsContainer>
        <PerformanceHeadline>Celebration Performances</PerformanceHeadline>
        {activeData.celebrationVideos.map((video) => (
          <VideoContainer>
            <HotspotVideo
              videoImage={video.videoImage}
              vimeoLink={video.videoLink}
              artistName={video.artistName}
              songTitle={video.songTitle}
            />
            <VideoTags>
              {[...video.videoTags]
                .sort((a, b) => {
                  if (b.tag === activeData.celebrationTitle) return 1;
                  if (a.tag === activeData.celebrationTitle) return -1;
                  return 0;
                })
                .map((tag) => (
                  <Tag className={tag.tag === activeData.celebrationTitle ? 'active' : ''}>{tag.tag}</Tag>
                ))}
            </VideoTags>
            <SongDescription>{video.songDescription}</SongDescription>
          </VideoContainer>
        ))}
      </CelebrationsContainer>
    </>
  );
};
