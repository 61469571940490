import concertThumbnail from '../assets/hotspot/celebrationPerformances/farah-ya-aroos.png';
import farahImage from '../assets/hotspot/headerImages/farah-l.png';
import coverPhoto from '../assets/hotspot/celebrationCover/jordanian-independence.jpg';

export const jordanianIndependenceDay = {
  celebrationTitle: 'Jordanian Independence Day',
  startDate: '05-25',
  endDate: '05-25',
  filterValue: 'jordanianIndependenceDay',
  celebrationImage: coverPhoto,
  displayDate: 'May 25th',
  description: 'Jordanian Independence Day is celebrated on May 25 every year and commemorates Jordan\'s independence from Britain.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/YmfEVD0zWO8',
      videoImage: concertThumbnail,
      songTitle: 'Ya Aroos il-Bahr',
      artistName: 'Farah',
      videoTags: [
        {
          tag: 'Jordanian Independence Day',
        },
      ],
      songDescription: '"Ya Aroos il-Bahr" is a song from the coast of Jordan inspired by the Red Sea. The song paints a picture of  the city Aqaba ornamented with flowers, palm trees, and palm leaves floating in the water.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Farah',
      artistId: 'farah',
      artistImage: farahImage,
    },
  ],
};
