// Data files
import { bongi } from '../data/bongi';
import { brianna } from '../data/brianna';
import { emeline } from '../data/emeline';
import { fabiana } from '../data/fabiana';
import { falu } from '../data/falu';
import { ilusha } from '../data/ilusha';
import { imani } from '../data/imani';
import { juan } from '../data/juan';
import { magda } from '../data/magda';
import { martha } from '../data/martha';
import { sofia } from '../data/sofia';
import { yacouba } from '../data/yacouba';
import { gregorio } from '../data/gregorio';
import { zulal } from '../data/zulal';
import { soulScienceLab } from '../data/soul-science-lab';
import { michael } from '../data/michael';
import { qianYi } from '../data/qian-yi';
import { layth } from '../data/layth';
import { villalobosBrothers } from '../data/villalobos-brothers';
import { julia } from '../data/julia';
import { etienne } from '../data/etienne';
import { tanyaradzwa } from '../data/tanyaradzwa';
import { starr } from '../data/starr';
import { quiana } from '../data/quiana';
import { farah } from '../data/farah';
import { vanAnh } from '../data/van-anh';
import { yasser } from '../data/yasser';
import { kalani } from '../data/kalani';

export const findArtistData = (artist) => {
  switch (artist) {
    case 'bongi-tshidi': return bongi;
    case 'brianna': return brianna;
    case 'emeline': return emeline;
    case 'fabiana': return fabiana;
    case 'falu': return falu;
    case 'ilusha': return ilusha;
    case 'imani': return imani;
    case 'juan-julia': return juan;
    case 'magda': return magda;
    case 'martha': return martha;
    case 'sofia-sofia': return sofia;
    case 'yacouba': return yacouba;
    case 'gregorio': return gregorio;
    case 'zulal': return zulal;
    case 'soul-science-lab': return soulScienceLab;
    case 'michael': return michael;
    case 'qian-yi': return qianYi;
    case 'layth': return layth;
    case 'villalobos-brothers': return villalobosBrothers;
    case 'julia': return julia;
    case 'etienne': return etienne;
    case 'tanyaradzwa': return tanyaradzwa;
    case 'starr': return starr;
    case 'quiana': return quiana;
    case 'farah': return farah;
    case 'vanAnh': return vanAnh;
    case 'yasser': return yasser;
    case 'kalani': return kalani;
    default: return null;
  }
};
