import aTrainThumbnail from '../assets/hotspot/celebrationPerformances/brianna-a-train.png';
import blueSkiesThumbnail from '../assets/hotspot/celebrationPerformances/brianna-blue-skies.png';
import briannaImage from '../assets/hotspot/headerImages/brianna-l.jpg';
import coverPhoto from '../assets/hotspot/celebrationCover/jazz-appreciation.png';

export const jazzAppreciationMonth = {
  celebrationTitle: 'Jazz Appreciation Month',
  startDate: '04-01',
  endDate: '04-30',
  filterValue: 'jazzAppreciationMonth',
  celebrationImage: coverPhoto,
  displayDate: 'The month of April',
  description: 'Jazz appreciation month is celebrated throughout April in Canada and the United States to pay tribute to jazz as both a living and a historic music.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/pi70_yRwxGw',
      videoImage: blueSkiesThumbnail,
      songTitle: 'Blue Skies',
      artistName: 'Brianna',
      videoTags: [
        {
          tag: 'Jazz Appreciation Month',
        },
      ],
      songDescription: 'Irving Berlin (1888–1989) is considered one of the greatest songwriters in American history.  Berlin wrote more than 1,500 songs, including hundreds of hits. “Blue Skies” was composed as part of a musical called Betsy. Audiences loved the song so much they demanded 24 encores of it on the show’s opening night!',
    },
    {
      videoLink: 'https://youtu.be/DvGVFcepJ-g',
      videoImage: aTrainThumbnail,
      songTitle: 'Take the A Train',
      artistName: 'Brianna',
      videoTags: [
        {
          tag: 'Jazz Appreciation Month',
        },
      ],
      songDescription: 'A wonderful introduction to jazz, “Take the A Train” features an iconic melody and a crystal-clear view into a specific place and time. After Duke Ellington hired Billy Strayhorn and wrote him directions to his house by subway, Strayhorn composed the legendary tune, and it became a staple of the Duke Ellington Orchestra. ',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Brianna',
      artistId: 'brianna',
      artistImage: briannaImage,
    },
  ],
};
