import { cincoDeMayo } from '../celebrationData/cinco-de-mayo';
import { hipHopCelebrationDay } from '../celebrationData/hip-hop-celebration-day';
import { navaratri } from '../celebrationData/navaratri';
import { lunarNewYear } from '../celebrationData/lunar-new-year';
import { cumbiaDay } from '../celebrationData/cumbia-day';
import { valentinesDay } from '../celebrationData/valentines-day';
import { jazzAppreciationMonth } from '../celebrationData/jazz-appreciation-month';
import { haitianIndependenceDay } from '../celebrationData/haitian-independence-day';
import { arabAmericanHistoryMonth } from '../celebrationData/arab-american-history';
import { jordanianIndependenceDay } from '../celebrationData/jordanian-independence-day';
import { heritageDay } from '../celebrationData/heritage-day';
import { nativeAmericanHeritageMonth } from '../celebrationData/native-american-heritage';
import { greenCornCeremony } from '../celebrationData/green-corn-ceremony';
import { trinidadTobagoCarnival } from '../celebrationData/trinidad-tobago-carnival';
import { dominicanIndependenceDay } from '../celebrationData/dominican-independence-day';
import { merrieMonarchFestival } from '../celebrationData/merrie-monarch-festival';
import { brazilianCarnival } from '../celebrationData/carnival-of-brazil';

export const celebrationDataMap = {
  haitianIndependenceDay,
  lunarNewYear,
  cumbiaDay,
  valentinesDay,
  dominicanIndependenceDay,
  brazilianCarnival,
  trinidadTobagoCarnival,
  merrieMonarchFestival,
  jazzAppreciationMonth,
  arabAmericanHistoryMonth,
  cincoDeMayo,
  jordanianIndependenceDay,
  greenCornCeremony,
  hipHopCelebrationDay,
  heritageDay,
  navaratri,
  nativeAmericanHeritageMonth,
};
