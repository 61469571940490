
import smallHeaderImage from '../assets/hotspot/headerImages/bongi-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/bongi-l.jpg';
import sofiasofiaPin from '../assets/hotspot/pins/sofiar-sofiat.jpg';
import marthaPin from '../assets/hotspot/pins/martha.jpg';
import ilushaPin from '../assets/hotspot/pins/ilusha.jpg';
import introImage from '../assets/hotspot/intro/bongi-tshidi.png';
import postcard from '../assets/hotspot/postcardPdf/Bongi_Tshidi_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/BongiTshidiPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/bongi_concert.png';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/bongi-inqola.png';

export const bongi = {
  name: 'Bongi and Tshidi',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Bongi and Tshidi, who sing South African Zulu music!',
    vimeoLink: 'https://vimeo.com/252744403',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/myT21gnyJDs',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Two/Bongi-and-Tshidi',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Bongi and Tshidi Sing “Thula Mntwana” / “Nampaya Omame”',
      link: 'Learn “Thula Mntwana” / “Nampaya Omame”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Two/Bongi-and-Tshidi/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/326227303%3Fsecret_token%3Ds-s3bUd&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Bongi and Tshidi Sing “Inqola”',
      link: 'Learn “Inqola”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Two/Bongi-and-Tshidi/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/326227296%3Fsecret_token%3Ds-gz1CM&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Bongi and Tshidi appear on stage with',
    artists: [
      {
        id: 1,
        image: sofiasofiaPin,
        name: 'Sofía R. and Sofia T. ',
        query: 'sofia-sofia',
      },
      {
        id: 2,
        image: marthaPin,
        name: 'Martha',
        query: 'martha',
      },
    ],
    linkOutText: 'Learn more about Bongi and Tshidi in Musical Explorers Program Two.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Two/',
  },
  relatedArtist2: {
    title: 'In Program Seven, Bongi and Tshidi appear on stage with',
    artists: [
      {
        id: 1,
        image: ilushaPin,
        name: 'Ilusha',
        query: 'ilusha',
      },
      {
        id: 2,
        image: marthaPin,
        name: 'Martha',
        query: 'martha',
      },
    ],
    linkOutText: 'Learn more about Bongi and Tshidi in Musical Explorers Program Seven',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Seven',
  },
  traditionBlurb: 'Zulu is a main culture in South Africa, the home of 10–11 million Zulu people. The music played by Sbongiseni “Bongi” Duma and Tshidi Manye combines deeply rooted Zulu traditions with more contemporary Zulu styles—all of which hinge on harmony. In many African traditions, melodies are sung in unison; in Zulu music, harmony emerges naturally whenever people sing together.',
  celebrationVideos: [
    {
      videoLink: 'https://www.youtube.com/watch?v=_z1OTljZDjQ',
      videoImage: celebrationThumbnail,
      songTitle: 'Inqola',
      artistName: 'Bongi and Tshidi',
      videoTags: [
        {
          tag: 'Heritage Day',
        },
      ],
      songDescription: 'The song "Inqola" speaks of the relationship between human and nature. "Inqola" means any type of moving vehicle, and this song honors the idea that we go through many phases in life, always moving, just like nature has its phases.',
    },
  ],
};
