import smallHeaderImage from '../assets/hotspot/headerImages/zulal-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/zulal-l.jpg';
import mus1 from '../assets/hotspot/pins/gregorio.jpg';
import mus2 from '../assets/hotspot/pins/soul-science-lab.jpg';
import introImage from '../assets/hotspot/intro/zulal.png';
import postcard from '../assets/hotspot/postcardPdf/Zulal_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/ZulalPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/zulal_concert.png';

export const zulal = {
  name: 'Zulal',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Zulal, who sing Armenian folk music!',
    details: '',
    vimeoLink: 'https://vimeo.com/202970367',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=sOcEiupK37Q',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Zulal',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Zulal sing “Doni Yar”',
      link: 'Learn “Doni Yar”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Zulal/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/277715088%3Fsecret_token%3Ds-yGBFn&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Zulal sing “Tamzara”',
      link: 'Learn “Tamzara”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Zulal/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/277715092%3Fsecret_token%3Ds-TkmoJ&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Zulal appears on stage with',
    artists: [
      {
        id: 1,
        image: mus1,
        name: 'Gregorio',
        query: 'gregorio',
      },
      {
        id: 2,
        image: mus2,
        name: 'Soul Science Lab',
        query: 'soul-science-lab',
      },
    ],
    linkOutText: 'Learn more about Zulal in Musical Explorers Program Five.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Five/',
  },
};
