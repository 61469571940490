import jazzCover from '../assets/hotspot/celebrationCover/jazz-appreciation.png';
import hipHopCover from '../assets/hotspot/celebrationCover/hip-hop.jpg';
import carnivalBrazilCover from '../assets/hotspot/celebrationCover/carnival-1.jpg';
import carnivalTrinidadCover from '../assets/hotspot/celebrationCover/carnival-2.jpg';
import cincoDeMayoCover from '../assets/hotspot/celebrationCover/cinco-de-mayo.jpg';
import navaratriCover from '../assets/hotspot/celebrationCover/navaratri.png';
import lunarNewYearCover from '../assets/hotspot/celebrationCover/lunar-new-year.jpg';
import merrieMonarchCover from '../assets/hotspot/celebrationCover/merrie-monarch-festival.jpg';
import haitianIndependenceCover from '../assets/hotspot/celebrationCover/haitian-independence.jpg';
import dominicanIndependenceCover from '../assets/hotspot/celebrationCover/dominican-independence.jpg';
import arabAmericanHistoryCover from '../assets/hotspot/celebrationCover/arab-american-history.jpg';
import jordanianIndependenceCover from '../assets/hotspot/celebrationCover/jordanian-independence.jpg';
import heritageDayCover from '../assets/hotspot/celebrationCover/heritage-day.jpg';
import nativeAmericanHeritageCover from '../assets/hotspot/celebrationCover/native-american-heritage.jpg';
import greenCornCeremonyCover from '../assets/hotspot/celebrationCover/green-corn-ceremony-2.jpg';

export const CELEBRATIONS_FILTERS = [
  {
    sectionTitle: 'Spring',
    sectionDateRange: 'March 1st - May 31st',
    filterItems: [
      {
        celebrationTitle: 'Brazilian Carnival',
        startDate: '02-28',
        endDate: '03-05',
        filterValue: 'brazilianCarnival',
        displayDate: 'Dates vary each year',
        celebrationImage: carnivalBrazilCover,
      },
      {
        celebrationTitle: 'Trinidad and Tobago Carnival',
        startDate: '03-03',
        endDate: '03-04',
        filterValue: 'trinidadTobagoCarnival',
        displayDate: 'Dates vary each year',
        celebrationImage: carnivalTrinidadCover,
      },
      {
        celebrationTitle: 'Jazz Appreciation Month',
        startDate: '04-01',
        endDate: '04-30',
        filterValue: 'jazzAppreciationMonth',
        celebrationImage: jazzCover,
        displayDate: 'The month of April',
      },
      {
        celebrationTitle: 'Cinco de Mayo',
        startDate: '05-05',
        endDate: '05-05',
        filterValue: 'cincoDeMayo',
        displayDate: 'May 5th',
        celebrationImage: cincoDeMayoCover,
      },
      {
        celebrationTitle: 'Merrie Monarch Festival',
        startDate: '3-31',
        endDate: '4-6',
        filterValue: 'merrieMonarchFestival',
        displayDate: 'Week after Easter',
        celebrationImage: merrieMonarchCover,
      },
      {
        celebrationTitle: 'Arab-American History Month',
        startDate: '04-01',
        endDate: '04-30',
        filterValue: 'arabAmericanHistoryMonth',
        displayDate: 'The month of April',
        celebrationImage: arabAmericanHistoryCover,
      },
      {
        celebrationTitle: 'Jordanian Independence Day',
        startDate: '05-25',
        endDate: '05-25',
        filterValue: 'jordanianIndependenceDay',
        displayDate: 'May 25th',
        celebrationImage: jordanianIndependenceCover,
      },
    ],
  },
  {
    sectionTitle: 'Summer',
    sectionDateRange: 'June 1st - August 31st',
    filterItems: [
      {
        celebrationTitle: 'Hip Hop Celebration Day',
        startDate: '08-11',
        endDate: '08-11',
        filterValue: 'hipHopCelebrationDay',
        displayDate: 'August 11th',
        celebrationImage: hipHopCover,
      },
      {
        celebrationTitle: 'Green Corn Ceremony',
        startDate: '06-22',
        endDate: '07-31',
        filterValue: 'greenCornCeremony',
        displayDate: 'Late June-July',
        celebrationImage: greenCornCeremonyCover,
      },
    ],
  },
  {
    sectionTitle: 'Fall',
    sectionDateRange: 'September 1st - November 30th',
    filterItems: [
      {
        celebrationTitle: 'Navaratri',
        startDate: '10-03',
        endDate: '10-12',
        filterValue: 'navaratri',
        displayDate: 'Dates vary each year',
        celebrationImage: navaratriCover,
      },
      {
        celebrationTitle: 'Heritage Day',
        startDate: '09-24',
        endDate: '09-24',
        filterValue: 'heritageDay',
        displayDate: 'September 24th',
        celebrationImage: heritageDayCover,
      },
      {
        celebrationTitle: 'Native American Heritage Month',
        startDate: '11-01',
        endDate: '11-30',
        filterValue: 'nativeAmericanHeritageMonth',
        displayDate: 'The month of November',
        celebrationImage: nativeAmericanHeritageCover,
      },
    ],
  },
  {
    sectionTitle: 'Winter',
    sectionDateRange: 'December 1st - February 28th',
    filterItems: [
      {
        celebrationTitle: 'Haitian Independence Day',
        startDate: '01-01',
        endDate: '01-01',
        filterValue: 'haitianIndependenceDay',
        displayDate: 'January 1st',
        celebrationImage: haitianIndependenceCover,
      },
      {
        celebrationTitle: 'Dominican Independence Day',
        startDate: '02-27',
        endDate: '02-27',
        filterValue: 'dominicanIndependenceDay',
        displayDate: 'February 27th',
        celebrationImage: dominicanIndependenceCover,
      },
      {
        celebrationTitle: 'Cumbia Day',
        startDate: '02-02',
        endDate: '02-02',
        filterValue: 'cumbiaDay',
        displayDate: 'February 2nd',
        celebrationImage: lunarNewYearCover,
      },
      {
        celebrationTitle: 'Valentine\'s Day',
        startDate: '02-14',
        endDate: '02-14',
        filterValue: 'valentinesDay',
        displayDate: 'February 14th',
        celebrationImage: lunarNewYearCover,
      },

      {
        celebrationTitle: 'Lunar New Year',
        startDate: '01-29',
        endDate: '02-12',
        filterValue: 'lunarNewYear',
        displayDate: 'Dates vary each year',
        celebrationImage: lunarNewYearCover,
      },
    ],
  },
];
