import smallHeaderImage from '../assets/hotspot/headerImages/quiana-s.png';
import largeHeaderImage from '../assets/hotspot/headerImages/quiana-l.png';
import introImage from '../assets/hotspot/intro/quiana.png';
import postcard from '../assets/hotspot/postcardPdf/Quiana_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/QuianaPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/quiana_concert.png';

export const quiana = {
  name: 'Quiana',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Quiana, who sings Gullah music!',
    details: '',
    vimeoLink: 'https://vimeo.com/747357890',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/-KuhLQkH7rA',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Quiana',
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Quiana sing “Kumbaya”',
      link: 'Learn “Kumbaya”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Quiana/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1340065399%3Fsecret_token%3Ds-nZp6dozLkyB&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Quiana sing “Green Sally”',
      link: 'Learn “Green Sally”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Quiana/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1340067211%3Fsecret_token%3Ds-AgsDbXRZsCC&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
};
