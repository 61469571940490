import coverPhoto from '../assets/hotspot/celebrationCover/carnival-2.jpg';
import concertThumbnail from '../assets/hotspot/celebrationPerformances/etienne-jouvert.png';
import etienneImage from '../assets/hotspot/headerImages/etienne-l.jpg';

export const trinidadTobagoCarnival = {
  celebrationTitle: 'Trinidad and Tobago Carnival',
  startDate: '03-03',
  endDate: '03-04',
  filterValue: 'trinidadTobagoCarnival',
  celebrationImage: coverPhoto,
  displayDate: 'Dates vary each year',
  description: 'Carnival is celebrated all over the world with street parties, parades, music, and dancing. Some of the most well known Carnival celebrations in the world are in Brazil and Trinidad.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/bt4kqjbNg-4',
      videoImage: concertThumbnail,
      songTitle: 'J’ouvert Barrio',
      artistName: 'Etienne',
      videoTags: [
        {
          tag: 'Trinidad and Tobago Carnival',
        },
      ],
      songDescription: ' The word "J\'ouvert" means "daybreak" in Creole, and the song "J\'ouvert Barrio" is sung at the beginning of Carnival.  Many people wear elaborate, colorful costumes that depict traditional Carnival characters, including the Sailor, Dragon, Bat, Pierrot (or clown), Cow, Jab Molassie (or devil), and Moko Jumbie (or stilt walker). ',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Etienne',
      artistId: 'etienne',
      artistImage: etienneImage,
    },
  ],
};
