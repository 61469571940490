import styled from 'styled-components/macro';

export const StateWrapper = styled.span`
  --translate3d: 0px, 0px, 0px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
  padding: 8px 11px;
  z-index: 2;
  font-size: 14px;
  transform-origin: top;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
  will-change: transform;
  transform-style: preserve-3d;
  transition: 300ms visibility, 300ms opacity;
  transform:
      translate3d(var(--translate3d))
      scale3d(var(--scaleValueLarge), var(--scaleValueLarge), 1);

  &.state-tooltip--brianna-1 {
    --translate3d: 396px,379px,0px;
  }
  &.state-tooltip--brianna-2 {
    --translate3d: 322px,434px,0px;
  }
  &.state-tooltip--emeline-1 {
    --translate3d: 419px,488px,0px;
  }
  &.state-tooltip--tanyaradzwa-1 {
    --translate3d: 866px, 671px, 0px;
  }
  &.state-tooltip--imani-1 {
    --translate3d: 289px,393px,0px;
  }
  &.state-tooltip--starr-1 {
    --translate3d: 230px, 403px, 0px;
  }
  &.state-tooltip--sofia-sofia-1 {
    --translate3d: 454px,747px,0px;
  }
  &.state-tooltip--martha-1 {
    --translate3d: 293px,426px,0px;
  }
  &.state-tooltip--bongi-tshidi-1 {
    --translate3d: 831px,712px,0px;
  }
  &.state-tooltip--magda-1 {
    --translate3d: 839px,388px,0px;
  }
  &.state-tooltip--yacouba-1 {
    --translate3d: 741px,495px,0px;
  }
  &.state-tooltip--falu-1 {
    --translate3d: 1103.5px,490px,0px;
  }
  &.state-tooltip--juan-julia-1 {
    --translate3d: 431px,492px,0px;
  }
  &.state-tooltip--juan-julia-2 {
    --translate3d: 678px,480px,0px;
  }
  &.state-tooltip--fabiana-1 {
    --translate3d: 508px,605px,0px;
  }
  &.state-tooltip--fabiana-2 {
    --translate3d: 667px,523px,0px;
  }
  &.state-tooltip--michael-1 {
    --translate3d: 369px,408px,0px;
  }
  &.state-tooltip--michael-2 {
    --translate3d: 710px,333px,0px;
  }
  &.state-tooltip--soul-science-lab-1 {
    --translate3d: 396px,379px,0px;
  }
  &.state-tooltip--soul-science-lab-2 {
    --translate3d: 387px,492px,0px;
  }
  &.state-tooltip--soul-science-lab-3 {
    --translate3d: 458px,530px,0px;
  }
  &.state-tooltip--soul-science-lab-4 {
    --translate3d: 690px,495px,0px;
  }
  &.state-tooltip--gregorio-1 {
    --translate3d: 401px,573px,0px;
  }
  &.state-tooltip--ilusha-1 {
    --translate3d: 911px,365px,0px;
  }
  &.state-tooltip--zulal-1 {
    --translate3d: 933px,388px,0px;
  }
  &.state-tooltip--layth-1 {
    --translate3d: 927px,424px,0px;
  }
  &.state-tooltip--qian-yi-1 {
    --translate3d: 1194px,383px,0px;
  }
  &.state-tooltip--farah-1 {
    --translate3d: 895px, 435px, 0px;
  }
  &.state-tooltip--quiana-1 {
    --translate3d: 360px, 420px, 0px; 
  }
  &.state-tooltip--julia-1 {
    --translate3d: 800px, 400px, 0px;
  }
  &.state-tooltip--vanAnh-1 {
    --translate3d: 1180px, 475px, 0px;
  }
  &.state-tooltip--etienne-1 {
    --translate3d: 430px, 540px, 0px;
  }
  &.state-tooltip--villalobos-brothers-1 {
    --translate3d: 280px, 500px, 0px;
  }
  &.state-tooltip--yasser-1 {
    --translate3d: 400px, 488px, 0px;
  }
  &.state-tooltip--kalani-1 {
    --translate3d: 100px, 430px, 0px;
  }
`;
