
import styled from 'styled-components/macro';
import intersection from 'lodash.intersection';
import { SvgMarkerText } from '../MapMarker/style';
import { Button } from '../Controls/style';

export const Wrapper = styled.div`
  max-width: 1586px;
  width: 100%;
  margin: 0 auto;

  ${({ theme: { media } }) => media.desktop`
    position: relative;
  `}
`;

export const MapWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1586px;
  width: 100%;
  opacity: 0;
  transition: opacity 500ms 0.4s;

  ${({ Loaded }) => (Loaded && `
    opacity: 1;
  `)}
`;

export const Map = styled.div`
  color: ${({ theme }) => theme.colors.black};
`;

export const TransformContainer = styled.div`
  width: 100%;

  .TransformComponent-module_container__3NwNd {
    width: 100%;
  }

  .react-transform-component {
    width: 100%;
    position: relative;
    overflow: hidden;
    user-select: none;
  }

  .react-transform-element {
    display: flex;
    flex-wrap: wrap;
    transform-origin: 0% 0%;
    width: 100%;
    height: 100vh;
    position: relative;
    ${({ shouldTransition }) => (shouldTransition ? 'transition: all 0.25s linear' : null)};
    backface-visibility: hidden;
    will-change: transform;

    justify-content: center;
    align-items: center;

    img {
      pointer-events: none;
    }
  }
`;

export const ZoomContainer = styled.div`
  display: none;

  ${Button} {
    top: 0;

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      right: 0;
    }
  }

  ${({ theme: { media } }) => media.hotspotPositionChange`
    display: block;
    position: absolute;
    bottom: 60px;
    right: 60px;
    z-index: 1;
    width: 99px;
    height: 33px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  `}
`;

export const PanContainer = styled.div`
  display: none;

  ${Button} {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    &:nth-child(1) {
      --transform: translate(-50%, -50%) rotate(270deg);
      top: 33px;
      left: 0;
    }

    &:nth-child(2) {
      --transform: translate(-50%, -50%) rotate(90deg);
      top: 33px;
      right: 0;
    }

    &:nth-child(3) {
      left: 33px;
      top: 0;
    }

    &:nth-child(4) {
      --transform: translate(-50%, -50%) rotate(180deg);
      bottom: 0;
      left: 33px;
    }
  }

  ${({ theme: { media } }) => media.hotspotPositionChange`
    display: block;
    position: absolute;
    top: 92px;
    right: 60px;
    z-index: 1;
    width: 99px;
    height: 99px;
  `}
`;


export const SvgComponent = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(0) scale(1.0, 1.0);
`;

export const SvgMarker = styled.div``;

export const SvgBackground = styled.img`
  width: 1586px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(0) scale(1.0, 1.0);
`;
export const SvgHawaii = styled.img`
  position: absolute;
  top: 47%;
  left: 3%;
  width: 58.9px;
  height: 38.095px; 
`;

export const SvgWater = styled.img`
  width: 1586px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(0) scale(1.0, 1.0);
  z-index: -1;
`;

export const MarkerButton = styled.button`
  --translate3d: 0px, 0px, 0px;
  --transition: opacity 300ms, visibility 300ms;
  margin: 0;
  padding: 0;
  line-height: 1;
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  outline: 0;
  position: absolute;
  width: 58px;
  height: 88px;
  z-index: 1;
  transform-origin: bottom center;
  will-change: transform;
  transform-style: preserve-3d;
  transition: var(--transition);
  cursor: pointer;
  pointer-events: auto;
  transform:
    translate3d(var(--translate3d))
    scale3d(var(--scaleValueNormal), var(--scaleValueNormal), 1);
  -webkit-tap-highlight-color: transparent;

  ${({ Filter, Program, celebrations }) => (
    (Filter.includes('all') || intersection(Filter, Program).length > 0 || (celebrations && celebrations.some((celebration) => Filter.includes(celebration)))) ? `
      visibility: visible;
      opacity: 1;
    ` : `
      visibility: hidden;
      opacity: 0;
    `
  )}

  ${({ theme: { media }, theme }) => media.desktop`
    ${({ Active }) => !Active && `
      &:hover, &:focus {
        ${SvgMarkerText} {
          visibility: visible;
          opacity: 1;
        }
      }
      &:focus {
        outline: 1px dashed ${theme.colors.alizarinCrimson};
      }
    `}
  `}

  ${({ Active }) => Active && `
    opacity: 0.3
  `}

  ${({ theme: { media } }) => media.desktop`
    ${({ Artist, Active }) => (Artist && !Active ? `
      &:hover {
        ~ svg .path--${Artist} {
          opacity: 1;
        }
      }
    ` : null)}
  `}

  ${({ Artist }) => Artist === 'emeline' && `
    --translate3d: 405px, 380px, 0px;
  `}
  ${({ Artist }) => Artist === 'yasser' && `
  --translate3d: 430px, 390px, 0px;
  `}
  ${({ Artist }) => Artist === 'kalani' && `
  --translate3d: 80px, 340px, 0px;
  `}
  ${({ Artist }) => Artist === 'ilusha' && `
    --translate3d: 915px, 273px, 0px;
  `}

  ${({ Artist }) => Artist === 'imani' && `
    --translate3d: 309px, 300px, 0px;
  `}

  ${({ Artist }) => Artist === 'sofia-sofia' && `
    --translate3d: 465px, 655px, 0px;
  `}

  ${({ Artist }) => Artist === 'martha' && `
    --translate3d: 353px, 334px, 0px;
  `}

  ${({ Artist }) => Artist === 'bongi-tshidi' && `
    --translate3d: 849px, 620px, 0px;
  `}

  ${({ Artist }) => Artist === 'magda' && `
    --translate3d: 842px, 296px, 0px;
  `}

  ${({ Artist }) => Artist === 'yacouba' && `
    --translate3d: 736px, 403px, 0px;
  `}

  ${({ Artist }) => Artist === 'falu' && `
    --translate3d: 1100px, 399px, 0px;
  `}

  ${({ Artist }) => Artist === 'brianna' && `
    --translate3d: 419px, 270px, 0px;
  `}

  ${({ Artist }) => Artist === 'juan-julia' && `
    --translate3d: 460px, 370px, 0px;
  `}

  ${({ Artist }) => Artist === 'fabiana' && `
    --translate3d: 536px, 531px, 0px;
  `}

  ${({ Artist }) => Artist === 'gregorio' && `
    --translate3d: 410px, 481px, 0px;
  `}

  ${({ Artist }) => Artist === 'zulal' && `
    --translate3d: 938px, 296px, 0px;
  `}

  ${({ Artist }) => Artist === 'soul-science-lab' && `
    --translate3d: 450px, 285px, 0px;
  `}

  ${({ Artist }) => Artist === 'michael' && `
    --translate3d: 390px, 300px, 0px;
  `}

  ${({ Artist }) => Artist === 'qian-yi' && `
    --translate3d: 1193px, 288px, 0px;
  `}

  ${({ Artist }) => Artist === 'villalobos-brothers' && `
    --translate3d: 280px, 390px, 0px;
  `}

  ${({ Artist }) => Artist === 'julia' && `
    --translate3d: 800px, 310px, 0px;
  `}

  ${({ Artist }) => Artist === 'etienne' && `
    --translate3d: 465px, 435px, 0px;
  `}

  ${({ Artist }) => Artist === 'tanyaradzwa' && `
    --translate3d: 879px, 559px, 0px;
  `}

  ${({ Artist }) => Artist === 'starr' && `
    --translate3d: 255px, 310px, 0px;
  `}

  ${({ Artist }) => Artist === 'vanAnh' && `
  --translate3d: 1220px, 375px, 0px;
  `}

  ${({ Artist }) => Artist === 'farah' && `
  --translate3d: 900px, 340px, 0px;
  `}

  ${({ Artist }) => Artist === 'layth' && `
  --translate3d: 935px, 330px, 0px;
`}

  ${({ Artist }) => Artist === 'quiana' && `
  --translate3d: 388px, 327px, 0px; 
  `}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'brianna' ? `
    ~ .state-tooltip--brianna {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'emeline' ? `
    ~ .state-tooltip--emeline {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'tanyaradzwa' ? `
    ~ .state-tooltip--tanyaradzwa {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'ilusha' ? `
    ~ .state-tooltip--ilusha {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'imani' ? `
    ~ .state-tooltip--imani {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'starr' ? `
    ~ .state-tooltip--starr {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'sofia-sofia' ? `
    ~ .state-tooltip--sofia-sofia {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'martha' ? `
    ~ .state-tooltip--martha {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'bongi-tshidi' ? `
    ~ .state-tooltip--bongi-tshidi {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'magda' ? `
    ~ .state-tooltip--magda {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'yacouba' ? `
    ~ .state-tooltip--yacouba {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'falu' ? `
    ~ .state-tooltip--falu {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'juan-julia' ? `
    ~ .state-tooltip--juan-julia {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'fabiana' ? `
    ~ .state-tooltip--fabiana {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'gregorio' ? `
    ~ .state-tooltip--gregorio {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'zulal' ? `
    ~ .state-tooltip--zulal {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'soul-science-lab' ? `
    ~ .state-tooltip--soul-science-lab {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'michael' ? `
    ~ .state-tooltip--michael {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'qian-yi' ? `
    ~ .state-tooltip--qian-yi {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'layth' ? `
    ~ .state-tooltip--layth {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'villalobos-brothers' ? `
    ~ .state-tooltip--villalobos-brothers {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'julia' ? `
    ~ .state-tooltip--julia {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}
  
  ${({ Artist, Active }) => (Artist === Active && Artist === 'etienne' ? `
    ~ .state-tooltip--etienne {
        visibility: visible;
        opacity: 1;
      }
  ` : null)}

  ${({ Artist, Active }) => (Artist === Active && Artist === 'farah' ? `
  ~ .state-tooltip--farah {
      visibility: visible;
      opacity: 1;
    }
` : null)}

${({ Artist, Active }) => (Artist === Active && Artist === 'vanAnh' ? `
~ .state-tooltip--vanAnh {
    visibility: visible;
    opacity: 1;
  }
` : null)}

${({ Artist, Active }) => (Artist === Active && Artist === 'quiana' ? `
~ .state-tooltip--quiana {
    visibility: visible;
    opacity: 1;
  }
` : null)}

${({ Artist, Active }) => (Artist === Active && Artist === 'yasser' ? `
~ .state-tooltip--yasser {
    visibility: visible;
    opacity: 1;
  }
` : null)}

${({ Artist, Active }) => (Artist === Active && Artist === 'kalani' ? `
~ .state-tooltip--kalani {
    visibility: visible;
    opacity: 1;
  }
` : null)}

  ${({ Artist, Active }) => Artist === Active && `
    z-index: 3 !important;
    opacity: 1 !important;
    transform:
      translate3d(var(--translate3d))
      scale3d(var(--scaleValueLarge), var(--scaleValueLarge), 1)
      !important;

    ~ svg .path--${Artist} {
      opacity: 1;
    }
  `}
`;

export const SvgContainer = styled.div`
  width: 1586px;
  height: 893px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(0) scale(1.0, 1.0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  pointer-events: none;

  ${({ theme: { media } }) => media.desktop`
    ${({ Active }) => !Active && `
      &:hover {
        button {
          opacity: 0.4;

          &:hover {
            opacity: 1;
            z-index: 2;
          }
        }
      }
    `}
  `}

  ${MarkerButton} {
    ${({ theme: { media } }) => media.desktop`
      ${({ Active }) => !Active && `
        &:hover, &:focus {
          transform:
            translate3d(var(--translate3d))
            scale3d(var(--scaleValueLarge), var(--scaleValueLarge), 1);
        }
      `}
    `}
  }

  .path {
    opacity: 0;
    transition: all 300ms;
  }
`;
